import { Container } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import IframeResizer from "iframe-resizer-react";

import config from "../../Config/config";
import { PLAN_SURVEY_ID } from "../../constants";

const DependentBoard = ({ owner }) => {
  const { counting, isShowIframe } = useSelector((state) => state.ui);
  const { sessionInfo } = useSelector((state) => state.account);

  const currentName = sessionInfo?.currentUser?.first_name;
  const { products = [], isPromoted } = sessionInfo?.currentUser || {};

  const apiUrl = config.apiHost;

  const isLockLegal = useMemo(() => {
    if (isPromoted) return false;
    const planningProducts = products.filter((it) => it.category === 'planning');
    return planningProducts?.length === 0;
  }, [products, isPromoted]);

  const surveyUrl = useMemo(() => {
    let link = `https://peacefully.qualtrics.com/jfe/form/${PLAN_SURVEY_ID}?fname=${owner?.first_name}&lname=${owner?.last_name}&email=${encodeURIComponent(owner?.email)}&contact_email=${encodeURIComponent(owner?.email)}&origin=${apiUrl}&CONTACT_ID=${owner.qualtrics_user_id}&isLockLegal=${isLockLegal}`;

    // handler_name is current user's name, who is handling owner's dashboard
    link += `&handler_name=${currentName}`;

    // description 1
    const description1 = `Welcome to the comprehensive guide.`;
    link += `&dashboard_description1=${encodeURIComponent(description1)}`;

    // description 2
    const description2 = `Please complete the following sections as if you were the person this plan is for.`;
    link += `&dashboard_description2=${encodeURIComponent(description2)}`;

    // description 3
    const description3 = `All sections are optional, and you may fill them out based on your needs. All your data will be auto-saved and protected by bank-level security.`;
    link += `&dashboard_description3=${encodeURIComponent(description3)}`

    return link;
    // eslint-disable-next-line
  }, [owner, isLockLegal])

  console.log('Dependent Survey URL: ', surveyUrl)

  return (
    <Container>
      <IframeResizer
        key={counting}
        title="Registration"
        id="qualtricsId"
        src={surveyUrl}
        style={{ width: "1px", minWidth: "100%", opacity: isShowIframe ? 1 : 0, visibility: isShowIframe ? 'visible' : 'hidden' }}
        checkOrigin={false}
        heightCalculationMethod="taggedElement"
      />
    </Container>
  );
}

export default DependentBoard;
